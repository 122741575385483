import React, { useContext, useState, useEffect, createContext } from "react";

import ps from "../modules/products";

export const ProductsContext = createContext();

function ProductsProvider({ children }) {

  const [areLoading, setAreLoading] = useState(true);
  const [products, setProducts] = useState({});

  useEffect(() => {
    ps.fetch_((products) => {
        setProducts(products);
        setAreLoading(false);
    })
  }, []);

  return (
    <ProductsContext.Provider value={{ products, areLoading }}>
      {children}
    </ProductsContext.Provider>
  );
}

export default ProductsProvider;

export function useProducts() {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error("Products.Context must be used within the Products.Provider");
  }
  return context;
}