import Button from '@rescui/button';

export function Upload({ currentImage, handleUpload }) {
    return (<Button
        className="button upload-button"
        mode="outline"
        size="s"
        >
        <label
            htmlFor="image-upload"
            className="upload-link"
        >
        { currentImage ? "Upload another image" : "Upload" }
        </label>
        <input
            className="upload-input"
            type="file"
            onChange={(e) => handleUpload(e.target.files)}
            id="image-upload"
            accept="image/jpeg, image/png, image/jpg"
        />
    </Button>)
}