import { useCollections } from "../../context/collections";
import { Link } from "react-router-dom";
import ms from "../../modules/modifiers";
import classnames from "classnames";

const COLLECTION_URL = '/collection/';

const isTemplateLink = (path) => path[1] && path[1].endsWith('-templates');

const pathToLink = (path) => path.length
    ?  (isTemplateLink(path) ? path.join(';') : COLLECTION_URL + path.join('.'))
    : '/admin';

const linkClassName = (path) => classnames([ isTemplateLink(path) ? 'admin-template-link' : 'admin-collection-link' ])

function Branch({ id, path, label, items, comment }) {
  return (
    <>
        <label htmlFor={ id }>
            <Link target="_blank" className={ linkClassName(path) } to={ pathToLink(path) }>{ label }</Link> { comment }
        </label>
        <ul id={ id }>

            { items.map((item, idx) => (
                <li key={ idx } >
                    { item.nest
                        ? <Branch key={ idx } id={ id + '-' + idx } path={ path.concat([ item.id ]) } label={ item.label } items={ item.nest } />
                        /* : <a target="_blank" href={ pathToLink(path.concat([ item.id ])) }>{ item.label }</a>*/
                        : <><Link className={ linkClassName(path) } target="_blank" to={ pathToLink(path.concat([ item.id ])) }>{ item.label }</Link>{ item.comment }</>
                    }
                </li>
                ))
            }
        </ul>
    </>
  )
}

function FullToc() {
  const collections = useCollections();
  const isLoading = collections.areLoading;
  // const expands = useState({});

  const templatesFor = (collection) => [
        { id : collection.id + '-templates', label : '[Templates]'
        , nest : collection.templates.map(
            (template) =>
              ({
                  id: collection.id + '--' + template.id,
                  label : template.name,
                  canBeExpanded : true,
                  expanded : false ,
                  comment : '(Group: ' + template.group + ')'
              })
            )
        }
    ];

  const items = !isLoading ?
    collections.all.map((collection) => (
        {
            id : collection.id,
            label : collection.name,
            nest : templatesFor(collection).concat(ms.buildModifierTree(collection.rootModifiers, collection.modifierSets)),
            canBeExpanded : false,
            expanded : true,
            comment : null
        }
    ))
    : [];

  return (
    !isLoading
      ? <Branch id={ 'root' } path={ [] } label={ "Collections" } items={ items } />
      : <></>
  );
}

export default FullToc;