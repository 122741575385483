import classnames from "classnames";
import { useVersion } from "../../context/version";
import { asset } from "../../modules/assets";

const IMG_ASSETS_PATH = 'assets/images';

export function DropZone({ image }) {
  return (
    <div
      className={ classnames([ 'image', `image--${image.id}`, `image--drop-zone` ]) }>
        <div />
    </div>
  );
}

export function Image({ image }) {
  const { hash } = useVersion();
  return (
      <img className={ classnames([ 'image', `image--${image.id}` ]) } src={ asset(IMG_ASSETS_PATH, image.url, hash)} />
  );
}

export function Images({ images }) {
  return (images && images.length
    ? <>
        { images.map(
            (image, index) =>
                (image.url !== "User")
                  ? <Image key={ index } image={ image } />
                  : <DropZone key={ index } image={ image } />
            )
        }
      </>
    : <></>)
}
