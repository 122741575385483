function makeVariations(count) {
  return [ ...Array(count).keys() ].map((_, varNum) => ({ id : `var${varNum}`, name : `Variation ${varNum}`, variation: true }));
}

export function findCurrent(set, needle) {
  return set.find(({ id }) => (needle == id))
}

export function findCurrentIdx(set, needle) {
  return set.findIndex(({ id }) => (needle == id))
}

function makeVariations_(count) {
  return [ ...Array(count).keys() ].map((_, varNum) => ({ id : `var${varNum}`, label : `Var.${varNum + 1}` }));
}

function buildModifierTree(setId, sets) {
  const currentSet = sets.find(({id}) => id == setId);
  if (!currentSet) return [];
  return currentSet.modifiers.map((modifier) => ({
    id : modifier.id,
    label : modifier.name,
    nest : modifier.nextModifiers ? buildModifierTree(modifier.nextModifiers, sets) : (modifier.variations ? makeVariations_(modifier.variations) : null)
  }))
}

function mapWithDefinitions_(setId, sets, modifiers, level) {
  if (level >= modifiers.length) return [];

  const currentSetIdx = findCurrentIdx(sets, setId);
  if (currentSetIdx < 0) {
    console.error(`modifier set definition ${setId} was not found for ${modifiers}`);
    return [];
  }

  const currentSet = sets[currentSetIdx];
  const currentModifierId = modifiers[level];
  const currentModifierIdx = findCurrentIdx(currentSet.modifiers, currentModifierId);
  if (currentModifierIdx < 0) {
    console.error(`modifier definition ${currentModifierId} was not found in set ${setId} for ${modifiers}`);
    return [];
  }

  const currentModifier = currentSet.modifiers[currentModifierIdx];
  const all = currentSet.modifiers.map(({ id, name }) => ({ id, name }));
  if (currentModifier.hasOwnProperty('variations')) {
    return [ { current : currentModifierId, currentIdx : currentModifierIdx, all } ]
        .concat([ { current: modifiers[ level + 1 ], currentIdx: 0, all : makeVariations(currentModifier.variations), variations : true }]);
  } else if (currentModifier.nextModifiers) {
    return [ { current : currentModifierId, currentIdx : currentModifierIdx, all } ]
        .concat(mapWithDefinitions_(currentModifier.nextModifiers, sets, modifiers, level + 1));
  } else {
    return [ { current : currentModifierId, currentIdx : currentModifierIdx, all } ];
  }

}

export function loadDefault(setId, sets) {
  if (!sets || !sets.length) return [];
  const setIdx = findCurrentIdx(sets, setId);
  const set = sets[setIdx];

  if (set && set.modifiers && set.modifiers.length) {
    const firstModifier = set.modifiers[0];
    if (firstModifier.variations) { // exists && > 0
      return [ firstModifier.id, 'var0' ];
    } else {
      if (firstModifier.nextModifiers) {
        return [ firstModifier.id ].concat(loadDefault(firstModifier.nextModifiers, sets));
      } else {
        return [ firstModifier.id ];
      }
    }
  } else {
    return [];
  }

}

function loadDefaultFor(setId, sets, modifiers, level) {
  if (!sets || !sets.length) return [];
  const setIdx = findCurrentIdx(sets, setId);
  const set = sets[setIdx];
  if (set && set.modifiers && set.modifiers.length) {
    const currentIdx = (level < modifiers.length) ? findCurrentIdx(set.modifiers, modifiers[level]) : 0;
    const currentModifier = set.modifiers[currentIdx];
    if (currentModifier.variations) { // exists && > 0
      return [ currentModifier.id, 'var0' ];  // it is the lowest level already
    } else {
      if (currentModifier.nextModifiers) {
        return [ currentModifier.id ].concat(loadDefaultFor(currentModifier.nextModifiers, sets, modifiers, level + 1));
      } else {
        return [ currentModifier.id ];
      }
    }

  }
}

export function change(modifiers, level, newValue) {
  if (!modifiers || modifiers.length < level) return modifiers;
  modifiers[level] = newValue;

  return modifiers.slice(0, level + 1);
}

export function apply(setId, sets, modifiers) {
  const defaultModifiers = loadDefaultFor(setId, sets, modifiers, 0);
  return defaultModifiers ? modifiers.concat(defaultModifiers.slice(modifiers.length)) : modifiers;
}

export const mapWithDefinitions = (rootSet, sets, modifiers) => mapWithDefinitions_(rootSet, sets, modifiers, 0);

export const updateCurrent = (mapping, modifiers) => mapping.map((def, level) => ({ current: modifiers[level], all : def.all }) )

export default
  { apply
  , change
  , updateCurrent
  , findCurrent
  , findCurrentIdx
  , loadDefault
  , mapWithDefinitions
  , buildModifierTree
  };