import './index.css';
import { useCollections } from "../../context/collections";
import { useState, useEffect, useCallback } from 'react';
import { useCurrentCollection } from "../../context/cur_collection";
import { useNavigate, useLocation } from "react-router-dom";
import { Select } from '@rescui/select';
import cs from "../../modules/collections";

function CollectionsDropdown({ id, focus, collections, order, current }) {
  const options = collections.map(({ id, name }) => ({ value: id, label: name }));

  const currentOption = current ? { value : current.id, label : current.name } :  options[0];
  //setSelected(currentOption);
  const navigate = useNavigate();
  const setSelected = useCallback((selected) => {
    navigate(cs.COLLECTION_URL + selected.value);
  })

  return (
    <div className={ `select-wrapper` } id="collections">
      { focus ? <label htmlFor="collections">{ focus.name }</label> : <></> }
      <Select
        options={options}
        value={currentOption}
        onChange={value => setSelected(value)}
        size="m"
      />
    </div>
  )
}

function Navigation() {
  const collections = useCollections();
  const currentCollection = useCurrentCollection();
  const isLoading = collections.areLoading || currentCollection.isLoading;
  const current =
      !isLoading && currentCollection.value
        ? cs.findById(collections.all, currentCollection.value)
        : null;

  return (
    !isLoading
      ? <CollectionsDropdown focus={ null } collections={ collections.all } order={ collections.order } current={ current } />
      : <></>
  );
}

export default Navigation;