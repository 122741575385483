import React, { useContext, useState, useEffect, useCallback, createContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { useCollections } from "../context/collections";

import ms from "../modules/modifiers";
import cs from "../modules/collections";
import url from "../modules/url";

export const CurrentCollectionContext = createContext();

function CurrentCollectionProvider({ children, fallbackToFirst }) {

    const params = useParams();
    const navigate = useNavigate();

    const collections = useCollections();
    const [ currentCollection, setCurrentCollection ] = useState(null);
    const [ modifiers, setModifiers ] = useState([]);

    const selectModifier = useCallback((level, newValue) => {
      const nextModifiers = ms.change(modifiers, level, newValue);
      setModifiers(nextModifiers);
      collections.storeModifiers(currentCollection, nextModifiers);
      navigate(url.build(currentCollection, nextModifiers), { replace: false });
    }, [ modifiers ])

    useEffect(() => {

      if (!collections.areLoading) {

        if (params.collectionAndModifiers) {
          // load current collection and modifiers from the URL

          const fromUrl = url.load(params.collectionAndModifiers);
          console.log("collection", fromUrl)

          const currentCollection = cs.findById(collections.all, fromUrl.collectionId);
          let nextModifiers = modifiers;
          if (currentCollection) {

              if (fromUrl.modifiers.length) {
                const appliedModifiers = ms.apply(currentCollection.rootModifiers, currentCollection.modifierSets, fromUrl.modifiers);
                setModifiers(appliedModifiers);
                collections.storeModifiers(fromUrl.collectionId, appliedModifiers);
                nextModifiers = appliedModifiers;
              } else if (collections.modifiersWereStored(fromUrl.collectionId)) {
                const storedModifiers = collections.getStoredModifiers(fromUrl.collectionId)
                setModifiers(storedModifiers);
                nextModifiers = storedModifiers;
              } else {
                const defaultModifiers = ms.loadDefault(currentCollection.rootModifiers, currentCollection.modifierSets);
                setModifiers(defaultModifiers);
                collections.storeModifiers(fromUrl.collectionId, defaultModifiers);
                nextModifiers = defaultModifiers;
              }

          }
          setCurrentCollection(fromUrl.collectionId);
          navigate(url.build(fromUrl.collectionId, nextModifiers), { replace: false });

        } else if (fallbackToFirst) {
          // load the first collection (from the registry) and its default modifiers as the current one

          const firstCollectionId = cs.findFirst(collections.order);
          if (firstCollectionId) {
              setCurrentCollection(firstCollectionId);
              const firstCollection = cs.findById(collections.all, firstCollectionId);
              const defaultModifiers = ms.loadDefault(firstCollection.rootModifiers, firstCollection.modifierSets);
              setModifiers(defaultModifiers);
              collections.storeModifiers(firstCollectionId, defaultModifiers);
              navigate(url.build(firstCollectionId, defaultModifiers), { replace: false });
          }

        }
        console.log('updated collection',currentCollection, modifiers)

      }
    }, [ collections, navigate ]);

    /* const location = useLocation();

    useEffect(() => {
      if (!location.pathname.startsWith('/just') && currentCollection) {
        navigate(cs.COLLECTION_URL + currentCollection);
      }
    }, [ ]); */

    return (
        <CurrentCollectionContext.Provider value={
            { value : currentCollection
            , isLoading : collections.areLoading
            , modifiers
            , selectModifier
            }
        }>
          {children}
        </CurrentCollectionContext.Provider>
      );
}

export default CurrentCollectionProvider;

export function useCurrentCollection() {
    const context = useContext(CurrentCollectionContext);
    if (context === undefined) {
      throw new Error("CurrentCollection.Context must be used within the CurrentCollection.Provider");
    }
    return context;
  }