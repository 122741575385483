import classnames from "classnames";
import { Select } from '@rescui/select';
import { useNavigate } from "react-router-dom";
import Switcher from '@rescui/switcher';

export default function ModifierPath({ path, collection, definitions, selectModifier }) {

  const navigate = useNavigate();

  const handleChange = (value, i, vari) => {
    const displayVal = vari ? value : value.value;
    selectModifier(i, displayVal);
    navigate(`/collection/${collection}.${ path.slice(0, -1).join('.') }.${displayVal}`);
  }

  return (
    definitions.map((level, i) => (

    (!level.variations) ? (
      <div key={ i } style={{ width: '200px', marginTop: "24px" }}>
        <Select
          value={ level.all.map(def => ({ value: def.id, label: def.name })).find(def => def.value === level.current)}
          options={level.all.map(def => ({ value: def.id, label: def.name }))}
          onChange={ value => { selectModifier(i, value.value) } }
          size="m"
        />
      </div>
      ) : (
      <div key={ i } style={{ width: '200px', marginTop: "24px" }}>
        <Switcher
          key={ i }
          value={level.all.map((def, k) => ({ value: def.id, label: def.name, order: k })).find(def => def.value === level.current)?.value || "var0"}
          onChange={value => handleChange(value, i, true)}
          size="xs"
          options={level.all.map((def, j) => ({ value: def.id, label: j }))}
        />
      </div>
      )
    ))
  )
}