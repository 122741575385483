import classnames from "classnames";
import { useVersion } from "../../context/version";
import { asset, ASSET_PREFIX } from "../../modules/assets";
import {useEffect} from "react";

const BG_ASSETS_PATH = 'assets/images';

function UnsetBackground({ width, height }) {
  return (
    <div
      className={ classnames([ 'background', 'background--unset' ]) }
      style={{ width, height }}
    />
  );
}

function ColorBackground({ width, height, color }) {
  return (
    <div
      className={ classnames([ 'background', 'background--color' ]) }
      style={{ width, height, backgroundColor: color }}
    />
  );
}

function UserBackground({ width, height }) {
  return (
    <div
      className={ classnames([ 'background', 'background--user' ]) }
      style={{ width, height }}
    />
  );
}

function ImageBackground({ width, height, url }) {
  return (
    <div
      className={ classnames([ 'background', 'background--image' ]) }
      style={{ width, height, backgroundImage: `url(${url})` }}
    />
  );
}

function ColorAndImageBackground({ width, height, color, url }) {
  return (
    <div
      className={ classnames([ 'background', 'background--color', 'background--image' ]) }
      style={{ width, height, backgroundColor: color, backgroundImage: `url(${url})` }}
    />
  );
}

function RandoloriumBackground({ layout, fill, head, body, leftHand, rightHand, width, height }) {
  return (
    <iframe src={
      `https://randolorium.labs.jb.gg/?gui=false&layout=${layout}&hand=${leftHand}&hand2=${rightHand}&fill=${fill}&head=${head}&body=${body}&zoom=${0.8}`
    } width={ width } height={ height }></iframe>
  );
}

export function Background({ bg, width, height }) {

  const { hash } = useVersion();

  if (!bg) {
    return <UnsetBackground width={ width } height={ height } />;
  };

  if (bg.url && bg.color) {
    return <ColorAndImageBackground width={ width } height={ height } color={ bg.color } url={ asset(BG_ASSETS_PATH, bg.url, hash) } />;
  }

  if (bg.color) {
    return <ColorBackground width={ width } height={ height } color={ bg.color } />;
  };

  if (bg.layout) {
    return (<RandoloriumBackground width={ width } height={ height }
        layout={ bg.layout }
        fill={ bg.fill }
        body={ bg.body }
        head={ bg.head }
        leftHand={ bg.leftHand }
        rightHand={ bg.rightHand }
      />);
  };

  if (bg.url) {
    return bg.url.startsWith(ASSET_PREFIX)
      ? <ImageBackground width={ width } height={ height } url={ asset(BG_ASSETS_PATH, bg.url, hash) } />
      : <UserBackground width={ width } height={ height } />;
  };

  return <UnsetBackground/>;
}