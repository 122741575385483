import {
    Routes,
    Route
  } from "react-router-dom";

import IndexRoute from './routes/index';
import CollectionRoute from './routes/collection';
import JustRoute from './routes/just'; // a.k.a `template`
import LoadRoute from './routes/load';
import RenderRoute from './routes/render';
import SaveRoute from './routes/save';
import { FullTocRoute, ConstructorRoute } from './routes/admin';

//import TemplateProvider from "./context/template";
import VersionProvider from "./context/version";

/*

    * / -> loads collections tree and selects the first one
    * /collection/:collection -> shows templates in given collection, listed
    * /load/:hash -> loads hash and navigates to the corresponding collection
    * /just/:template -> shows a single template
    * /render/:hash -> show a scene rendered
    * /save -> saves current state of edits at the collection
    * /export -> exports current state to file
    * /icons -> special view

    * Top level: (no UI: no menu, no buttons, etc.)
        * /load/:hash
        * /save (navigates to `collection/:collection` or previous state (to what was saved) back)
        * /export (navigates back on success)
        * /render/:hash
    * Within app:
        * /
        * /collection/:collection
        * /just/:template
        * /icons

*/

function BakerRoutes() {
    return (
        <VersionProvider>
            <Routes>
                <Route path="load/:hash" element={<LoadRoute />} />
                <Route path="save" element={<SaveRoute />} />
                <Route path="render/:hash" element={<RenderRoute />} />
                <Route path="admin" element={<FullTocRoute />} />
                {/* <Route path="constructor/:hash" element={<ConstructorRoute />} /> */}
                <Route path="constructor" element={<ConstructorRoute />} />
                <Route path="/" element=
                    {
                        <IndexRoute />
                    }>

                    <Route path="just/:template" element={
                        <JustRoute />
                    } />

                    <Route path="collection/:collectionAndModifiers" element={
                        <CollectionRoute />
                    } />

                    <Route index element={
                        <CollectionRoute fallbackToFirst={ true } />
                    } />

                </Route>
                <Route
                    path="*"
                    element={
                    <main style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                    </main>
                    }
                />
            </Routes>
        </VersionProvider>
    );
};


export default BakerRoutes;