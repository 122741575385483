import CollectionsProvider from "../context/collections";
import CurrentCollectionProvider from "../context/cur_collection";
import TemplatesProvider from "../context/templates";
import EditsProvider from "../context/edits";

import Saver from "../components/saver/saver";

function SaveRoute() {
    return (
      <CollectionsProvider>
        <CurrentCollectionProvider>
          <TemplatesProvider>
            <EditsProvider>
              <Saver />
            </EditsProvider>
          </TemplatesProvider>
        </CurrentCollectionProvider>
      </CollectionsProvider>
    );
}

export default SaveRoute;