import CollectionsProvider from "../context/collections";
import CurrentCollectionProvider from "../context/cur_collection";
import TemplatesProvider from "../context/templates";
import EditsProvider from "../context/edits";

import CurrentCollection from "../components/collection/cur_collection";

function CollectionRoute({ fallbackToFirst }) {
    return (
      <CollectionsProvider>
        <CurrentCollectionProvider fallbackToFirst={ fallbackToFirst }>
          <TemplatesProvider>
            <EditsProvider>
              <CurrentCollection />
            </EditsProvider>
          </TemplatesProvider>
        </CurrentCollectionProvider>
      </CollectionsProvider>
    );
}

export default CollectionRoute;