import React, { useContext, useState, createContext, useCallback, useMemo } from "react";
import {useNavigate} from "react-router-dom";

export const EditsContext = createContext(
  { texts : {}, setText : () => {}
  , removeText : () => {}
  , images : {}, setImage : () => {}
  , sharedTexts : {}, setSharedText : () => {}
  , sharedImages : {}, setSharedImage : () => {}
  , backgrounds : {}, setBackground : () => {}
  , locks : {}, setLocks : () => {}
  });


function EditsProvider({ children }) {

  const [ texts, setTexts ] = useState({}); // template ID -> text ID -> text value
  const [ images, setImages ] = useState({}); // template ID -> image ID -> image URL / base 64 + offset
  const [ sharedTexts, setSharedTexts ] = useState({}); // collection ID -> text ID -> text value
  const [ sharedImages, setSharedImages ] = useState({}); // collection ID -> text ID -> text value
  const [ backgrounds, setBackgrounds ] = useState({}); // template ID -> image URL / base 64 + offset
  const [ editsSaved, setEditsSaved ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ locks, setLocks] = useState({});


  const setLock = (templateId, value) => {
    setLocks((curLocked) => {
      const nextLocked = {...curLocked};
      nextLocked[templateId] = value;
      console.log('set lock', templateId, value)
      return nextLocked;
    })
    setEditsSaved(false);
  }

  const removeText = (templateId) => {
    setTexts((curTexts) => {
      const nextTexts = {...curTexts};
      nextTexts[templateId] = null;
      console.log('remove texts', templateId, nextTexts)
      return nextTexts;
    });
    setEditsSaved(false);
  }

  const setText = (templateId, textId, text) => {
    setTexts((curTexts) => {
      /* if (...) return { ...curTexts, templateId: {}}
      return { ...curTexts, templateId: { ...curTexts.templateId, textId: text }} */
      const nextTexts = {...curTexts};
      if (!nextTexts[templateId]) { nextTexts[templateId] = {}; }
      // nextTexts[templateId] = {...nextTexts[templateId]};
      nextTexts[templateId][textId] = text;
      console.log('setting etxt:', text)
      return nextTexts;
    });
    setEditsSaved(false);
  };

  const setImage = (templateId, imageId, image, offset) => {
    setImages((curImages) => {
      const nextImages = {...curImages};
      if (!nextImages[templateId]) { nextImages[templateId] = {}; }
      nextImages[templateId] = {...nextImages[templateId]};
      nextImages[templateId][imageId] = image;
      return nextImages;
    });
    setEditsSaved(false);
  };

  const setSharedText = (collectionId, textId, text) => {
    setSharedTexts((curSharedTexts) => {
      const nextSharedTexts = {...curSharedTexts};
      if (!nextSharedTexts[collectionId]) { nextSharedTexts[collectionId] = {}; }
      nextSharedTexts[collectionId] = {...nextSharedTexts[collectionId]};
      nextSharedTexts[collectionId][textId] = text;
      return nextSharedTexts;
    });
    setEditsSaved(false);
  };

  const setSharedImage = (collectionId, imageId, image, offset) => {
    setSharedImages((curSharedImages) => {
      const nextSharedImages = {...curSharedImages};
      if (!nextSharedImages[collectionId]) { nextSharedImages[collectionId] = {}; }
      nextSharedImages[collectionId] = {...nextSharedImages[collectionId]};
      nextSharedImages[collectionId][imageId] = image;
      return nextSharedImages;
    });
    setEditsSaved(false);
  };

  const setBackground = (templateId, image, offset) => {
    setBackgrounds((curBackgrounds) => {
      const nextBackgrounds = {...curBackgrounds};
      nextBackgrounds[templateId] = { value : image, offset };
      return nextBackgrounds;
    });
    setEditsSaved(false);
  };

  const markSaved = () => {
    setEditsSaved(true);
  };

  const invalidate = () => {
    setEditsSaved(false);
  };

  const loadFrom = useCallback((other) => {
    setBackgrounds(other.backgrounds);
    setImages(other.images);
    setSharedImages(other.sharedImages);
    setTexts(other.texts);
    setLocks(other.locks);
    setSharedTexts(other.sharedTexts);
    //setEditsSaved(false);
  }, [ texts, images, backgrounds, sharedTexts, sharedImages, loading ]);

  return (
    <EditsContext.Provider value={{
        texts, setText, removeText,
        locks, setLock,
        images, setImage,
        sharedTexts, setSharedText,
        sharedImages, setSharedImage,
        backgrounds, setBackground,
        editsSaved, markSaved, invalidate,
        loading, setLoading, loadFrom
      }}>
        { children }
    </EditsContext.Provider>
  );
}

export default EditsProvider;

export function useEdits() {
  const context = useContext(EditsContext);
  if (context === undefined) {
    throw new Error("Edits.Context must be used within the Edits.Provider");
  }
  return context;
}