import React from "react";
import classnames from "classnames";

function makeClassnames(templateId, maybeGroup, modifiers) {
  return classnames(
    [ 'template'
    , 'template--' + templateId
    , maybeGroup ? `group--${maybeGroup}` : ''
    ].concat(modifiers.map(( mod ) => `mod--${mod}`))
     .concat(modifiers.map(( mod, index ) => `mod--${index}--${mod}`))
  );
}

export const Wrapper = React.forwardRef(({ children, template, id, modifiers, width, height, zoom }, ref) => {
  return (
    <div
      style={ { width, height, zoom } }
      className={ makeClassnames(id, template.group, modifiers) }
      ref = { ref }
    >
      { children }
    </div>
  )
})

export function EmptyWrapper({ children, width, height, id, modifiers }) {
  return (
    <svg width={ width } height={ height } style={ { display: "block", marginBottom: 40 }}>
      <rect width={ width } height={ height } fill="lightgray" />
      <text x={ 5 } y={ 5 } fontSize={ 20 } fill={ "black" } stroke={ "black" } style={ { dominantBaseline : "hanging" } }>
          { id }/{ modifiers.join('/') }
      </text>
    </svg>
  )
}