import { useParams } from "react-router-dom";



import CollectionsProvider from "../context/collections";
import CurrentCollectionProvider from "../context/cur_collection";
import TemplatesProvider from "../context/templates";
import EditsProvider from "../context/edits";

import Loader from "../components/loader/loader";
import log from "tailwindcss/lib/util/log";

function LoadRoute() {
    let { hash } = useParams();

    return (
      <CollectionsProvider>
        <CurrentCollectionProvider>
          <TemplatesProvider>
            <EditsProvider>
              <Loader hash={ hash } />
            </EditsProvider>
          </TemplatesProvider>
        </CurrentCollectionProvider>
      </CollectionsProvider>
    );
}

export default LoadRoute;