import { useCurrentTemplate } from "../../context/cur_template";
import { useCurrentCollection } from "../../context/cur_collection";
import { useEdits } from "../../context/edits";

import { save } from "../../modules/save";
import { useEffect } from "react";

import {
    useNavigate
} from "react-router-dom";


function Saver() {

    const currentTemplate = useCurrentTemplate();
    const currentCollection = useCurrentCollection();
    const edits = useEdits();

    const navigate = useNavigate();

    console.log('----');
    console.log(edits);
    console.log(currentCollection);
    console.log(currentTemplate);


    useEffect(() => {
        if (!edits.editsSaved) {
            save({ edits, currentTemplate, currentCollection }, (scene) => {
                console.log('saved', scene);

                edits.markSaved();
                if (scene.scene_id) navigate('/load/' + scene.scene_id);
                if (scene.hash) navigate('/load/' + scene.hash);
            });
        }
    }, [ edits ]);

    return (
      <h2>Saver</h2>
    );
}

export default Saver;