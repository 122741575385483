const KRAKEN_API = "https://kraken.labs.jb.gg";
//const KRAKEN_API = "https://limb.us-east1-gke.intellij.net";

const SAVE_SCENE_URL = `${KRAKEN_API}/scene/save?prefix=baker-next`;
// const SAVE_SCENE_URL = `${KRAKEN_API}/save_scene`;

export function save(data, whenDone, onError) {
    fetch(`${SAVE_SCENE_URL}`, {
        headers : {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        },
        method : 'POST',
        body: JSON.stringify({ prefix: "baker-next", payload: data })
    })
    .then(function (response) {
        if (!response.ok) {
            throw new Error('Not 2xx response');
        }
        return response.json();
    })
    .then(whenDone)
    .catch((error) => onError ? onError(error) : null);
}

export default { save };