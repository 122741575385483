import classnames from "classnames";

export function Buttons({ buttons }) {
  return (buttons && buttons.length
    ? <div className="buttons">
        { buttons.map(
            (button, index) =>
                <Button
                  key={ index }
                  index={ index }
                  background={ button.background }
                  text={ button.text } />
            )
        }
      </div>
    : <></>)
}

export function Button({ index, background, text }) {
  const className = classnames([ 'button' ])
  // TODO : background, link
  return (
    <a href="#"
      className={ className }
      >{ text }</a>
  );
}