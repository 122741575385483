import classnames from "classnames";
import { useVersion } from "../../context/version";
import { asset } from "../../modules/assets";

const LOCAL_LOGOS_PATH = `/assets/images/blog`;
const CUSTOM_LOGOS_PATH = `assets/images`;

function assetUrl(kind, url) {
  switch (kind) {
    case 'custom':
      return asset(CUSTOM_LOGOS_PATH, url);
    case 'placeholder':
      return null;
    default:
      return asset(LOCAL_LOGOS_PATH, url);
  }
}

export function Logos({ logos, onSelect }) {
  return (logos && logos.length
    ? <div className="logos">
        { logos.map(
            (logo, index) =>
                <Logo
                  key={ index }
                  index={ index }
                  id={ logo.of }
                  kind={ logo.kind }
                  product={ logo.product }
                  url={ assetUrl(logo.kind, logo.url) }
                  onSelect = { onSelect }
                />
            )
        }
      </div>
    : <></>)
}

function UsualLogo({ src, className, selectHandler }) {
  return (<img
    className={ className }
    width={ 220 } height={ 220 }
    src={ src }
    onClick={ selectHandler }
  />);
}

function LogoWithText({ product, src, className, selectHandler }) {
  return (<div className="logo-with-text-wrapper">
  <img
    className={ className }
    width={ 220 } height={ 220 }
    src={ src }
    onClick={ selectHandler }
  />
  <span className="logo-text">{ product || '' }</span>
</div>);
}


function LogoPlaceholder({ className, selectHandler }) {
  return (<div
    className={ className }
    width={ 220 } height={ 220 }
    onClick={ selectHandler }>
      <span>+</span>
  </div>);
}

export function Logo({ index, id, kind, url, product, onSelect }) {
  const { hash } = useVersion();
  const hashedSrc= `${url}?${hash}`;
  const className = classnames([ 'logo', `logo--idx-${index}`, id ? `logo--${id}` : null, `logo--${kind}` ])
  const selectHandler = onSelect ? onSelect({ index, id, of : id, kind, url, product }) : null;

  switch (kind) {
    case 'with-text':
      return <LogoWithText product={ product } className={ className } src={ hashedSrc } selectHandler={ selectHandler }/>;
    case 'placeholder':
      return <LogoPlaceholder className={ className } selectHandler={ selectHandler } />;
    default:
      return <UsualLogo className={ className } src={ hashedSrc } selectHandler={ selectHandler }/>;
  }

}