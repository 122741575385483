const COLLECTIONS_URL = "/data/collections.json";
const COLLECTION_URL = '/collection/';


export function findById(collections, id) {
  let index = collections.findIndex((collection) => collection.id == id);
  return (index >= 0) ? collections[index] : null;
}

export function findFirst(order) {
  if ((order.length > 0) && order[0] && (order[0].length == 2)) {
    if (!order[0][1]) return null;
    if (order[0][1].length == 0) {
      return order[0][0];
    } else {
      return findFirst(order[0][1]);
    }
  } else return null;
}

export function fetch_(onReceive/*, onError*/) {
    fetch(COLLECTIONS_URL, {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
    })
    .then(function (response) {
        return response.json();
    })
    .then(onReceive)
    .catch((error) => console.error(error));
}

export default { findById, findFirst, fetch_, COLLECTION_URL };