import Button from '@rescui/button';
import { useTextStyles } from '@rescui/typography';

export default function Zoom({ current, zoomIn, zoomOut }) {

  const textCn = useTextStyles();

  const displayCurrent = (current) => Math.round((current || 1.0) * 100) + '%'; // Math.floor(current * 100) / 100


  return (
    <div style={ { paddingTop : 32 } }>
        <Button mode="outline" size="xs" onClick={zoomOut}>-</Button>
        <span style={ { paddingLeft : 8, paddingRight : 8 } } className={ textCn("rs-text-2") }>{ displayCurrent(current) }</span>
        <Button mode="outline" size="xs" onClick={zoomIn}>+</Button>
    </div>

  )
}