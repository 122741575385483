import React, { useContext, useEffect, useState, createContext } from "react";

const VERSION = '0.8.0'; // FIXME: use from environment

const VersionContext = createContext({ version : VERSION, hash : "" });

function VersionProvider({ children }) {
    const value = useState(VERSION);

    // FIXME: use from environment
    const [hash, setHash] = useState({});

    useEffect(() => {
        setHash(generateHash(8));
    }, []);

    return (
      <VersionContext.Provider value={ { value, hash } }>
          { children }
      </VersionContext.Provider>
    );
}

export default VersionProvider;

export function useVersion() {
  const context = useContext(VersionContext);
  if (context === undefined) {
    throw new Error("Version.Context must be used within the Version.Provider");
  }
  return context;
}

function generateHash(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}