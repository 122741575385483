import { useCallback, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

import EditsProvider, { useEdits } from "../../context/edits";
import { useZoom } from "../../context/zoom";
import { useTextStyles } from '@rescui/typography';

import "./colors.css";
import "./template.prelude.css";
import "./template.instances.css";

import { Wrapper, EmptyWrapper } from "./wrapper";
import { Background } from "./background";
import { Texts } from "./texts";
import { Logos } from "./logos";
import { Buttons } from "./buttons";
import { Images } from "./images";
import { InfoBlock } from "./infoblock";
import Checkbox from '@rescui/checkbox';

const Template = React.forwardRef(({ template, id, name, modifiers, fromCollection, zoom }, ref) => {
  const edits = useEdits();
  const textCn = useTextStyles();
  const [isLocked, setIsLocked] = useState(false);

  const templateId = id;
  const collectionId = fromCollection ? fromCollection.id : null;

  const loadText =  useCallback((textId) => {
    console.log('load')
    const customText = edits.texts[templateId] ? edits.texts[templateId][textId] : null;
    const sharedText = (collectionId && edits.sharedTexts[collectionId]) ? edits.sharedTexts[collectionId][textId] : null;
    return customText !== null ? customText : sharedText;
  }, [ edits.texts, edits.sharedTexts, edits.locks ]);

  const updateText = useCallback((textId, newText) => {
    if (edits.locks[templateId]) {
      edits.setText(templateId, textId, newText);
    }

    if (collectionId && !edits.locks[templateId]) {
      edits.setSharedText(collectionId, textId, newText);
    }
  }, [ edits.texts, edits.sharedTexts, edits.locks ]);

  const updateLock = useCallback(value => {
    edits.setLock(templateId, value);
    edits.removeText(templateId);
  })

  const loadImage = (imageId) => {
    const customImage = edits.images[templateId] ? edits.images[templateId][imageId] : null;
    const sharedImage = (collectionId && edits.sharedImages[collectionId]) ? edits.sharedImages[collectionId][imageId] : null;
    return customImage || sharedImage;
  };

  const updateImage = (imageId) => (newImage) => {
    edits.setSharedImage(collectionId, imageId, newImage);
  };

  return (template ?
    <div className={ `template-and-info` } >
      <h4 style={{marginBottom: "16px"}} className={textCn('rs-text-1')}>
        {/*{ name }*/}
        <Link to={ `/just/${id}${ modifiers.length ? '.' + modifiers.join('.') : '' }` } className={textCn('rs-link', { external: true, mode: 'standalone' })}>
            {/* modifiers.length ? id + '.' + modifiers.join('.') : '-' */}
            { name }
        </Link>

        <span className={ `template-size` }>{ template.width }x{ template.height }</span>
        <Checkbox
          checked={!!edits.locks[templateId] || isLocked}
          onChange={e => updateLock(e.target.checked)}
        >
          Locked
        </Checkbox>
      </h4>
      <Wrapper ref={ ref } width={ template.width } height={ template.height } template={ template } id={ id } modifiers={ modifiers } zoom={ zoom } >
        <Background bg={ template.background } width={ template.width } height={ template.height } />
        <Logos logos={ template.logos } />
        <Buttons buttons={ template.buttons } />
        <Texts
          edits={edits.texts}
          shared={edits.sharedTexts}
          texts={ template.texts }
          loadText={ loadText }
          updateText={ updateText }
        />
        <Images images={ template.images } loadImage={ loadImage } updateImage={ updateImage } />
      </Wrapper>
      <div className={ `template-show-info` }>
          <span className={ `template-show-info-button` }>ℹ️</span>
          <InfoBlock template={ template } id={ id } modifiers={ modifiers } />
      </div>
    </div>
    :

    <EmptyWrapper width={ 500 } height={ 400 } id={ id } modifiers={ modifiers } />
  );
})

export default Template;
