import React, { useContext, useState, useEffect, createContext } from "react";

import { useTemplates } from "./templates";

import t from "../modules/template";

export const CurrentTemplateContext = createContext(
  { template : null
  , name : ''
  , id : null
  , modifiers : []
  });

function CurrentTemplateProvider({ id, name, modifiers, children }) {

  const [ template, setTemplate ] = useState(null);
  const { templates, areLoading } = useTemplates();

  useEffect(() => {
    if (!areLoading) {
      const templateFromRegistry = t.load(templates, id, modifiers);
      if (templateFromRegistry) {
        setTemplate(templateFromRegistry);
      } else {
        console.error(`Template ${id}.${modifiers.join('.')} was not found.`);
        setTemplate(null);
      }
    }
  }, [ areLoading, templates, modifiers ]);

  return (
    <CurrentTemplateContext.Provider value={{ template, name, id, modifiers }}>
      {children}
    </CurrentTemplateContext.Provider>
  );
}

export default CurrentTemplateProvider;

export function useCurrentTemplate() {
  const context = useContext(CurrentTemplateContext);
  if (context === undefined) {
    throw new Error("CurrentTemplate.Context must be used within the CurrentTemplate.Provider");
  }
  return context;
}