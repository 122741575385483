/* eslint-disable no-unused-expressions */
import React, { createRef, useCallback, useMemo, useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import html2canvas from 'html2canvas';
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { useTemplates } from "../../context/templates";
import { useEdits } from "../../context/edits";

import Template from "../template/template";
import Button from '@rescui/button';

import ModifierPath from "./modifier_path";

import ms from "../../modules/modifiers";
import t from "../../modules/template";
import s from "../../modules/save";

import './collection.css';
import log from "tailwindcss/lib/util/log";
import { useTextStyles } from "@rescui/typography";
import CollectionsProvider from "../../context/collections";
import CurrentCollectionProvider, {useCurrentCollection} from "../../context/cur_collection";
import ZoomProvider from "../../context/zoom";
import Navigation from "../navigation/navigation";
import Zoom from './zoom';
import { saveScene } from "../save/save";
import {useCurrentTemplate} from "../../context/cur_template";

//const modifiersMappingCache = {};

function Collection({ collection, modifiers, selectModifier }) {
  const collectionTemplates = collection.templates;
  const anyCollectionTemplates = collectionTemplates && (collectionTemplates.length > 0);
  const mappedModifiers = collection.rootModifiers
      ? ms.mapWithDefinitions(collection.rootModifiers, collection.modifierSets, modifiers) : [];

  const { templates, areLoading } = useTemplates();
  const edits = useEdits();
  const currentTemplate = useCurrentTemplate();
  const navigate = useNavigate();
  let currentCollection = useCurrentCollection();
  let refs = {};

  const refsById = useMemo(() => {
    refs = {};
    collectionTemplates.forEach((item) => {
      refs[item.id] = React.createRef(null)
    })
    return refs
  }, [collection, collectionTemplates])

  const removeGrammarly = () => {
    const grammarly = document.querySelectorAll('grammarly-extension');
    const grammarlyArray = [...grammarly];
    grammarlyArray.forEach(el => {
      el.remove();
    })
  }

  const preferredZoom = collection.zoom;
  const [currentZoom, setZoom] = useState(preferredZoom != 'Auto' ? preferredZoom : 1.0);
  const [zipTitle, setZipTitle] = useState("");

  const zoomIn = () => { setZoom((current) => Math.min(4.0, current + 0.1)); };
  const zoomOut = () => { setZoom((current) => Math.max(0.10, current - 0.1)); };

  const handleExport = useCallback(() => {
    const nodes = Object.keys(refs).map(ref => refs[ref].current);
    exportLayouts(nodes)
      .catch((e) => console.log(e))
  }, [collection, currentCollection, collectionTemplates])


  const exportLayouts = async () => {
    removeGrammarly();
    const dropzones = document.querySelectorAll(".image--drop-zone");
    if (dropzones.length > 0) [...dropzones].forEach(element => { element.classList.add("invisible") })

    const urls = Object.values(refs).filter(ref => !!ref.current).map( async ({ current}) => {
      const canvas = await html2canvas(current);
      const w = canvas.width;
      const h = canvas.height;
      const ratio = window.devicePixelRatio;
      const image = document.createElement('canvas');
      image.width = w / ratio;
      image.height = h / ratio;
      const ctx = image.getContext('2d');
      ctx.drawImage(canvas, 0,0, w / ratio, h / ratio);
      const url = image.toDataURL();
      return url
    })


    Promise.all(urls)
      .then( array => {
        const zip = new JSZip();

        array.forEach((url, i) => {
          const name =  Object.keys(refs)[i];
          zip.file(
            `${name}.png`,
            url.substring("data:image/png;base64,".length),
            {base64: true}
          );
        })

        return zip.generateAsync({type: "blob"});
      })
      .then(result => {
        const collection = currentCollection.value;
        const mods = currentCollection.modifiers.join("_");
        console.log('currentCollection', currentCollection);
        const name = collection && (mods.length > 0) ? `${collection}_${mods}.zip` : 'images.zip';
        setZipTitle(name);
        saveAs(result, name);
      })
      .catch((e) => console.log('Error downloading images', e));

    if (dropzones.length > 0) [...dropzones].forEach(element => { element.classList.remove("invisible") })
  }

  const saveLayouts = useCallback(() => {
    const data = { currentTemplate, currentCollection, edits };
    console.log('collection', edits)
    saveScene(data, navigate);
  })


  const loadTemplate =
    useCallback(
       (templateId) => !areLoading ? t.load(templates, templateId, modifiers) : {}
    , [ areLoading, templates, modifiers ]);



  const textCn = useTextStyles();



  /* const save = useCallback(() => {
    console.log(edits.sharedTexts, edits.texts, collection.id, modifiers);
    s.save({ sharedTexts : edits.sharedTexts, texts : edits.texts }, (resp) => { console.log(resp) },  (err) => { console.log(err) });
  }, [ edits, collection, modifiers ]); */

  return (
    <div className={ `collection-wrapper` }>
      <main className={ `collection-main` } style={ { overflow : 'hidden' } } >

        {/*<h1 className={textCn('rs-h1')}>*/}
        {/*  {collection.name}*/}
        {/*  <Link className={ `collection-link` } to={ `/collection/${collection.id}` }>🔗</Link>*/}
        {/*</h1>*/}



        <div className={ `collection--${collection.id}` } >
            { anyCollectionTemplates && collectionTemplates.map(({ id, name }) => (
                <Template
                  key={ id }
                  template={ loadTemplate(id) }
                  id={ id }
                  name={ name }
                  modifiers={ modifiers }
                  fromCollection={ collection }
                  ref={ refsById[id] }
                  zoom={ currentZoom }
                />
            )) }
        </div>
      </main>
      <aside>

        { mappedModifiers && (mappedModifiers.length > 0)
          && (
              <ModifierPath
                collection={ collection.id }
                path={ modifiers }
                definitions={ mappedModifiers }
                selectModifier={ selectModifier }
              />
          )
        }


        <div
          style={{ marginTop: "24px", display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            onClick={handleExport}
            size="m"
            className={ "export" }
            id={ "export_button" }
          >
              Export
          </Button>
          <span className="zip-title">{zipTitle}</span>
        </div>

        {/*<div style={{ marginTop: "24px", display: "flex", justifyContent: "flex-start" }}>
          <Button
            onClick={saveLayouts}
            size="m"
            className={ "save" }
            id={ "save_button" }
          >
            Save
          </Button>
        </div>*/}

        <Zoom current={ currentZoom } zoomIn={ zoomIn } zoomOut={ zoomOut }/>

      </aside>
    </div>
  );
}

export default Collection;