//const PRODUCTS_URL = "/data/products.json"; // FIXME> from brand data?
const PRODUCTS_URL = 'https://resources.jetbrains.com/cai/brand-data/products.json';

export function findById(products, id) {
  let index = products.findIndex((product) => product.id == id);
  return (index >= 0) ? products[index] : null;
}

export function fetch_(onReceive/*, onError*/) {
    fetch(PRODUCTS_URL, {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
    })
    .then(function (response) {
        return response.json();
    })
    .then((json) => onReceive(json.all))
    .catch((error) => console.error(error));
}

export default { findById, fetch_, PRODUCTS_URL };