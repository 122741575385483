import CollectionsProvider from "../context/collections";
import CurrentCollectionProvider from "../context/cur_collection";
import TemplatesProvider from "../context/templates";
import ProductsProvider from "../context/products";

import FullToc from "../components/admin/full_toc";
import Constructor from "../components/admin/constructor";

function FullTocRoute({ fallbackToFirst }) {
    return (
      <CollectionsProvider>
        <CurrentCollectionProvider fallbackToFirst={ fallbackToFirst }>
          <TemplatesProvider>
            <FullToc />
          </TemplatesProvider>
        </CurrentCollectionProvider>
      </CollectionsProvider>
    );
}

function ConstructorRoute({ fallbackToFirst }) {
  return (
    <ProductsProvider>
      <CollectionsProvider>
        <CurrentCollectionProvider fallbackToFirst={ fallbackToFirst }>
          <TemplatesProvider>
            <Constructor />
          </TemplatesProvider>
        </CurrentCollectionProvider>
      </CollectionsProvider>
    </ProductsProvider>
  );
}

export { FullTocRoute, ConstructorRoute };