import { Link } from "react-router-dom";

import './infoblock.css';

function bgInfo(bg) {
  if (!bg) return (<em>None</em>);
  if (bg.url && bg.color) return (bg.url + " on " + bg.color);
  if (bg.url) return bg.url;
  if (bg.color) return bg.color;
  if (bg.layout) return bg.layout + ":" + bg.fill + ":" + bg.body + ":" + bg.head + ":" + bg.rightHand + ":" + bg.leftHand;
  return (<em>Unknown</em>);
}

export function InfoBlock({ id, template, modifiers }) {
  return(<div className={ `info-block` }>
    <h5>{ template.name }
        <Link to={ `/just/${id}.${ modifiers.join('.') }` } className={ `template-link` }>
            { modifiers.length ? id + '.' + modifiers.join('.') : '-'}
        </Link>
    </h5>

    <ul>
      <li><strong>group</strong> { template.group }</li>
      <li><strong>width</strong> { template.width }</li>
      <li><strong>height</strong> { template.height }</li>
      <li><strong>background</strong>&nbsp; { bgInfo(template.background) }</li>
      <li><strong>texts</strong>&nbsp;
        { template.texts && template.texts.length
            ? template.texts.map(t => `${t.id}::${t.kind}::${t.text}`).join(', ')
            : <em>None specified</em>
        }
      </li>
      <li><strong>logos</strong>&nbsp;
        { template.logos && template.logos.length
            ? template.logos.map(l => `${l.kind}::${l.of}`).join(', ')
            : <em>None specified</em> }
      </li>
      <li><strong>images</strong>&nbsp;
        { template.images && template.images.length
            ? template.images.map(t => `${t.id}::${t.url}`).join(', ')
            : <em>None specified</em>
        }
      </li>
    </ul>
  </div>)
}