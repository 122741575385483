import {useCallback} from "react";
import {useCurrentTemplate} from "../../context/cur_template";
import {useCurrentCollection} from "../../context/cur_collection";
import {useEdits} from "../../context/edits";

const KRAKEN_API = "https://kraken.labs.jb.gg";
const SAVE_SCENE_URL = `${KRAKEN_API}/scene/save?prefix=baker-next`;

export const saveScene = async (data, navigate) => {
  try {
    const response = await fetch(`${SAVE_SCENE_URL}`, {
      headers : {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      method : 'POST',
      body: JSON.stringify({ prefix: "baker-next", payload: data })
    });
    console.log("save params: ", data)
    const scene = await response.json();
    navigate(`/load/${scene.scene_id}`);

  } catch(err) {
    console.error(err); // TypeError: failed to fetch
  }
}