import React, { useContext, useState, useEffect, createContext } from "react";

import t from "../modules/template";

export const TemplatesContext = createContext();

function TemplatesProvider({ fromCollection, children }) {

  const [areLoading, setAreLoading] = useState(true);
  const [templates, setTemplates] = useState({});

  useEffect(() => {
    t.fetch_((templates) => {
      setTemplates(templates);
      setAreLoading(false);
    })
  }, []);

  return (
    <TemplatesContext.Provider value={{ templates, areLoading, fromCollection }}>
      {children}
    </TemplatesContext.Provider>
  );
}

export default TemplatesProvider;

export function useTemplates() {
  const context = useContext(TemplatesContext);
  if (context === undefined) {
    throw new Error("Templates.Context must be used within the Templates.Provider");
  }
  return context;
}