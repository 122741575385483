import { useCollections } from "../../context/collections";
import { useCurrentCollection } from "../../context/cur_collection";

import Collection from "../collection/collection";
import cs from "../../modules/collections";

function CurrentCollection() {
    let collections = useCollections();
    let currentCollection = useCurrentCollection();
    let isLoading = collections.areLoading || currentCollection.isLoading;
    let collection =
        !isLoading && currentCollection.value
          ? cs.findById(collections.all, currentCollection.value)
          : null;

    return (
      isLoading
        ? <p>Loading...</p>
        : (collection
              ? <Collection
                  id={ currentCollection.value }
                  collection={ collection }
                  modifiers={ currentCollection.modifiers }
                  selectModifier={ currentCollection.selectModifier }
                />
              : <>Collection not found: { currentCollection.value }</>
          )
    );
}

export default CurrentCollection;