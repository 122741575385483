const KRAKEN_API = "https://kraken.labs.jb.gg";
//const KRAKEN_API = "https://limb.us-east1-gke.intellij.net";

const LOAD_SCENE_URL = (sceneId) => `${KRAKEN_API}/scene/load?prefix=baker-next&scene_id=${sceneId}`;
// const LOAD_SCENE_URL = (sceneId) => `${KRAKEN_API}/load_scene?prefix=baker-next&scene_id=${sceneId}`;


export function load(hash, whenDone, onError) {
    fetch(LOAD_SCENE_URL(hash), {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    })
    .then(function (response) {
        if (!response.ok) {
            throw new Error('Not 2xx response');
        }
        return response.json();
    })
    .then(whenDone)
    .catch((error) => onError ? onError(error) : null);
}

export default { load };