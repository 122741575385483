import classnames from "classnames";
import ContentEditable from 'react-contenteditable';
import {useEffect} from "react";

export function Texts({ edits, shared, texts, loadText, updateText }) {

  return (texts && texts.length
    ? <>
        { texts.map(
            (text, index) => {
                // console.log("TEXTLOAD", loadText(text.id))
                return (<Text
                  key={ index }
                  id={ text.id }
                  kind={ text.kind }
                  text={ loadText(text.id) || ""}
                  updateText={ value => { updateText(text.id, value) } }
                  setInitText={() => updateText(text.id, text.text)}
                />)
              }
            )
        }
      </>
    : <></>)
}

export function Text({ id, kind, text, updateText, setInitText }) {
  useEffect(() => { setInitText(text.id) }, []);

  return (<ContentEditable
    //innerRef={this.contentEditable}
    className={ classnames([ 'text', `text--${id}`, `text--kind--${kind}` ]) }
    html={ text }
    disabled={ false }
    onChange={(evt) => { updateText(evt.target.value) }}
    tagName='span'
  />);

}
