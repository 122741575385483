import { Link } from "react-router-dom";

import CollectionsProvider from "../../context/collections";
import CurrentCollectionProvider from "../../context/cur_collection";
import Navigation from "../../components/navigation/navigation";
import { useTextStyles } from '@rescui/typography';
import { ThemeIcon } from '@rescui/icons';
import Switcher from '@rescui/switcher';
import { ThemeProvider, useTheme } from '@rescui/ui-contexts';
import { RS_COLOR_BLACK_T5, RS_COLOR_GREY_90 } from '@rescui/colors';


const EXAMPLE_TEMPLATE_ID = [ "blog_image", "ac", "roadmap", "var1" ].join('.')


function Index({ children, changeTheme }) {
  const theme = useTheme();

    return (
      <div style={{
        display: "flex",
        flexDirection : "column",
        minHeight: "100vh",
        minWidth: "1350px",
        backgroundColor: theme === "light" ? RS_COLOR_BLACK_T5 : RS_COLOR_GREY_90
      }} >
        <nav className={'nav'}>
          <CollectionsProvider>
            <CurrentCollectionProvider>
              <Navigation />
            </CurrentCollectionProvider>
          </CollectionsProvider>
        </nav>

        <div className={'page'}>
          { children }
        </div>

        <div style={{ position: "fixed", right: "64px", bottom: "32px", zIndex: "1"}}>
          <Switcher
            value={theme}
            onChange={changeTheme}
            size="xs"
            options={[
              { label: 'Light', value: 'light' },
              { label: 'Dark', value: 'dark' },
            ]}
          />
        </div>
      </div>
    );
}

export default Index;