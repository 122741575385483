import { useCurrentTemplate } from "../../context/cur_template";
import { useCurrentCollection } from "../../context/cur_collection";
import { useEdits } from "../../context/edits";

import { load } from "../../modules/load";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const KRAKEN_API = "https://kraken.labs.jb.gg";
const LOAD_SCENE_URL = `${KRAKEN_API}/scene/load?prefix=baker-next&scene_id=`;

function Loader({ hash }) {

    const currentTemplate = useCurrentTemplate();
    const currentCollection = useCurrentCollection();
    const edits = useEdits();
    console.log('HASH', hash)

    const navigate = useNavigate();

    useEffect( async () => {
        const params = await load();
        const newEdits = params.edits;
        const collectionId = params.currentCollection.value;
        const mods = params.currentCollection.modifiers.join(".");
        const path = `/collection/${params.currentCollection.value}.${mods}`
        navigate(path)
        edits.setSharedText(collectionId, "title", newEdits.sharedTexts[collectionId].title);
    });

    const load = async () => {
        try {
            const response = await fetch(`${LOAD_SCENE_URL}${hash}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            });
            const scene = await response.json();
            const params = scene.payload;

            return params;
        } catch(err) {
            console.error(err); // TypeError: failed to fetch
        }
    }

    return (
      <h2>Loading { hash }</h2>
    );
}

export default Loader;