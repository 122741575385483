import { useParams } from "react-router-dom";

import TemplatesProvider from "../context/templates";
import CurrentTemplateProvider from "../context/cur_template";

import CurrentTemplate from "../components/template/cur_template";
// import ms from "../modules/modifiers.js";


function JustRoute() {
    const { template } = useParams();
    const fullPath = template.split('.');
    const templateId = fullPath[0];
    const modifiers = fullPath.splice(1);

    return (
        <TemplatesProvider>
          <CurrentTemplateProvider id={ templateId } modifiers={ modifiers }>
            <CurrentTemplate />
          </CurrentTemplateProvider>
        </TemplatesProvider>
    );
}

export default JustRoute;