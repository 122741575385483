const TEMPLATES_URL = "/data/templates.json";

function toTemplateId(id, modifiers) {
  return modifiers.length ? `${id}.${modifiers.join('.')}` : id;
}

export function fetch_(onReceive, onError) {

  fetch(`${TEMPLATES_URL}`, {
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache'
    }
  })
  .then(function (response) {
    if (!response.ok) {
      throw new Error('Not 2xx response');
    }
    return response.json();
  })
  .then(onReceive)
  .catch((error) => onError ? onError(error) : null);

}

export function load(templates, id, modifiers) {
  const key = toTemplateId(id, modifiers);
  return templates[key];
}

export default { fetch_, load };