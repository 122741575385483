import { useCurrentTemplate } from "../../context/cur_template";

import Template from "../template/template";

function CurrentTemplate() {
    const { template, id, name, modifiers, fromCollection } = useCurrentTemplate();

    return (
        <Template
            template={ template }
            id={ id }
            name={ name }
            modifiers={ modifiers }
            fromCollection={ fromCollection }
        />
    );
}

export default CurrentTemplate;