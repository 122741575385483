import { Outlet } from "react-router-dom";
import { useState } from 'react';
import { ThemeProvider } from '@rescui/ui-contexts';
import CurrentCollectionProvider from "../context/cur_collection";
import CollectionsProvider from "../context/collections";
import TemplatesProvider from "../context/templates";
import EditsProvider from "../context/edits";

import Index from "../components/index/index";


function IndexRoute() {
  const [theme, setTheme] = useState('dark');

    return (
      <ThemeProvider theme={theme}>
        <Index changeTheme={setTheme}>
            <Outlet />
        </Index>
      </ThemeProvider>

    );
}

export default IndexRoute;