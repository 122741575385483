import React, { useContext, useState, useEffect, useCallback, createContext } from "react";

import cs from "../modules/collections";

export const CollectionsContext = createContext();

function CollectionsProvider({ children }) {

  const [collections, setCollections] = useState({});
  const [order, setOrder] = useState([]);
  const [areLoading, setAreLoading] = useState(true);
  const [storedModifiers, setStoredModifiers] = useState({});

  const storeModifiers = useCallback((collectionId, modifiers) => {
    setStoredModifiers((prevModifiers) => {
      prevModifiers[collectionId] = modifiers;
      return prevModifiers;
    })
  }, [ ]);

  const modifiersWereStored = useCallback((collectionId) => {
    return storedModifiers.hasOwnProperty(collectionId);
  }, [ storedModifiers ]);

  const getStoredModifiers = useCallback((collectionId) => {
    return storedModifiers[collectionId];
  }, [ storedModifiers ]);

  useEffect(() => {
    cs.fetch_((collectionsJson) => {
      const { collections, order } = collectionsJson
      setCollections(collections);
      setOrder(order);
      setAreLoading(false);
    });
  }, []);

  return (
    <CollectionsContext.Provider value={
      { all : collections
      , order
      , areLoading
      , modifiersWereStored
      , getStoredModifiers
      , storeModifiers
      }}>
      {children}
    </CollectionsContext.Provider>
  );
}

export default CollectionsProvider;

export function useCollections() {
  const context = useContext(CollectionsContext);
  if (context === undefined) {
    throw new Error("Collections.Context must be used within the Collections.Provider");
  }
  return context;
}