import { useCallback, useState } from "react";
import React from "react";
import { useCollections } from "../../context/collections";
import { useProducts } from "../../context/products";

import { Link } from "react-router-dom";

import "../template/colors.css";
import "../template/template.prelude.css";
import "../template/template.instances.css";

import Template from "../template/template";
import { Wrapper, EmptyWrapper } from "../template/wrapper";
import { Background } from "../template/background";
import { Texts } from "../template/texts";
import { Logos } from "../template/logos";
import { Buttons } from "../template/buttons";
import { Images } from "../template/images";
import { InfoBlock } from "../template/infoblock";
import { Upload } from './upload';

import Input from '@rescui/input';
import { Select } from '@rescui/select';
import Button from '@rescui/button';

const COLLECTION_URL = '/collection/';

/* const updateItem = function update(arr, idx, updatedData) {
  return arr.map((item, index) => (index === idx ? { ...item, ...updatedData } : item))
} */

const updateItem = function update(arr, idx, newItem) {
  return arr.map((item, index) => (index === idx ? newItem : item));
}

const removeItem = function remove(arr, idx) {
  return arr.splice(idx, 1);
}


const defaultTemplate = () => ({
    "background": {
      "color": "var(--primary)"
    },
    "buttons": [
      {
        "background": {
          "color": "#ffffff"
        },
        "text": "Button"
      }
    ],
    "group": "ad_horz_middle",
    "height": 640,
    "images": [],
    "logos": [
      {
        "kind": "logo",
        "of": "marketplace",
        "product": "Marketplace",
        "url": "https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Logos_Icons/Marketplace/JetBrains_Marketplace_icon.svg"
      }/*,
      {
        "kind": "placeholder"
      }*/
    ],
    "texts": [
      {
        "id": "product-name",
        "kind": "product-name",
        "text": "Marketplace"
      },
      {
        "id": "tagline",
        "kind": "tagline",
        "text": "Tagline..."
      }
    ],
    "width": 1940
  });


// const products = [ { id : 'marketplace', 'name' : 'Marketplace' }, { id : 'jetbrains', name : 'JetBrains' } ];

function productToDropDownItem(product) {
  return { id: product.key, name : product.name };
}

const PRESELECTED_PRODUCT_ID = 'Marketplace';


function Constructor({ template, onUpdate }) {
  const collections = useCollections();
  const products = useProducts();
  const isLoading = collections.areLoading || products.areLoading;
  const productsMap = products.areLoading ? {} : products.products;
  const productsList = Object.keys(productsMap).map(key => productToDropDownItem(productsMap[key]));
  var productsById = {};
  if (!products.areLoading) {
      Object.keys(productsMap).forEach((pKey) => {
        productsById[productsMap[pKey].key] = productsMap[pKey];
      });
  }

  const [ curTemplate, setTemplate ] = useState(template || defaultTemplate());
  const [ allowEdits, setAllowEdits ] = useState(true);
  const [ currentSubject, setCurrentSubject ] = useState(null);
  const [ currentProduct, changeProduct ] = useState({ id : 'marketplace', name : 'Marketplace' });

  const [ uploadedImage, setUploadedImage ] = useState(null);

  const uploadTest = (files) => { console.log(files[0]); setUploadedImage(URL.createObjectURL(files[0])); };

  const setWidth = (width) => { setTemplate((curTemplate) => ({ ...curTemplate, width : width })) };
  const setHeight = (height) => { setTemplate((curTemplate) => ({ ...curTemplate, height : height })) };
  const changeBackground = (newBackground) => { setTemplate((curTemplate) => ({ ...curTemplate, background : newBackground })) };
  const changeLogo = (index, logo) => { setTemplate((curTemplate) => ({ ...curTemplate, logos : updateItem(curTemplate.logos, index, logo) })) };
  const changeText = (index, text) => { setTemplate((curTemplate) => ({ ...curTemplate, texts : updateItem(curTemplate.texts, index, text) })) };
  const changeImage = (index, image) => { setTemplate((curTemplate) => ({ ...curTemplate, images : updateItem(curTemplate.images, index, image) })) };
  const changeButton = (index, button) => { setTemplate((curTemplate) => ({ ...curTemplate, buttons : updateItem(curTemplate.buttons, index, button) })) };
  const addText = () => { setTemplate((curTemplate) => ({ ...curTemplate, text : curTemplate.texts.concat({ id: 'custom', kind : 'custom', text : 'Text' })  })) }
  const addLogo = () => { setTemplate((curTemplate) => ({ ...curTemplate, logos : curTemplate.logos.concat({ kind : 'custom', product : 'jetbrains', of : 'jetbrains' })  })) }
  const addButton = () => { setTemplate((curTemplate) => ({ ...curTemplate, buttons : curTemplate.buttons.concat({ background : { color : 'blue' }, text : 'Button' })  })) }
  const addImage = () => { setTemplate((curTemplate) => ({ ...curTemplate, images : curTemplate.images.concat({ id : 'custom', url : '' })  })) }
  const removeLogo = (index) => { setTemplate((curTemplate) => ({ ...curTemplate, logos : removeItem(curTemplate.logos, index) })) };
  const removeText = (index) => { setTemplate((curTemplate) => ({ ...curTemplate, texts : removeItem(curTemplate.texts, index) })) };
  const removeImage = (index) => { setTemplate((curTemplate) => ({ ...curTemplate, iamges : removeItem(curTemplate.images, index) })) };
  const removeButton = (index) => { setTemplate((curTemplate) => ({ ...curTemplate, buttons : removeItem(curTemplate.buttons, index) })) };

  const modifiers = productsById[currentProduct.id] ? [ productsById[currentProduct.id].shortKey ] : [];

  return (
    !isLoading
      ? <div id="constructor">
          { uploadedImage && <img src={ `${uploadedImage}` } width="300" height="300" /> }
          <TemplateInspector products={ productsList } currentProduct={ currentProduct } changeProduct={ changeProduct } width={ curTemplate.width } height={ curTemplate.height } setWidth={ setWidth } setHeight={ setHeight } />
          <ItemInspector subject={ currentSubject } />
          <EditableTemplate template={ curTemplate } name={ "Constructor" } id="foobar" fromCollection="admin" modifiers={ modifiers } allowEdits={ allowEdits } changeSubject={ setCurrentSubject } />
          { /* <h2>Preview 🢃</h2>
          <Template template={ curTemplate } name={ "Constructor" } id="foobar" fromCollection="admin" modifiers={ [ 'marketplace' ] } /> } */}
          <h3>Background</h3>
          <BackgroundInspector background={ curTemplate.background } changeBackground={ changeBackground } />
          <h3>Texts</h3>
          { curTemplate.texts.map((text, index) =>
                (<div key={ index }>
                    <TextInspector index={ index } text={ text } changeText={ changeText } removeText={ removeText }  />
                </div>)
            )
          }
          <Button size="s" onClick={ addText }>Add Text ({ curTemplate.texts.length })</Button>
          <h3>Logos</h3>
          { curTemplate.logos.map((logo, index) =>
                (<div key={ index }>
                    <LogoInspector index={ index } logo={ logo } changeLogo={ changeLogo } currentProduct={ currentProduct } removeLogo={ removeLogo } />
                </div>)
            )
          }
          <Button size="s" onClick={ addLogo }>Add Logo ({ curTemplate.logos.length })</Button>
          <h3>Images</h3>
          { curTemplate.images.map((image, index) =>
                (<div key={ index }>
                    <ImageInspector index={ index } image={ image } changeImage={ changeImage } removeImage={ removeImage }  />
                </div>)
            )
          }
          <Button size="s" onClick={ addImage }>Add Image ({ curTemplate.images.length })</Button>
          <h3>Buttons</h3>
          { curTemplate.buttons.map((button, index) =>
                (<div key={ index }>
                    <ButtonInspector index={ index } button={ button } changeButton={ changeButton } removeButton={ removeButton } />
                </div>)
            )
          }
          <Button size="s" onClick={ addButton }>Add Button ({ curTemplate.buttons.length })</Button>
          <h3>Upload</h3>
          <Upload handleUpload={ uploadTest }/>
        </div>
      : <></>
  );
}

function TemplateInspector({ width, height, products, currentProduct, changeProduct, setWidth, setHeight }) {
  /* Size, product, collection, modifiers, go here */
  return (
    <div>
      <Input
        value={width}
        id="width"
        size="s"
        type="number"
        step="8"
        onChange={e => setWidth(+e.target.value)}
      />
      <Input
        value={height}
        id="height"
        size="s"
        type="number"
        step="8"
        onChange={e => setHeight(+e.target.value)}
      />
      <ProductsDropdown
          products={ products }
          current={ currentProduct }
          onChange={ changeProduct }
      />
    </div>
  );
}

function BackgroundInspector({ background, changeBackground }) {
    // image URL
    // and/or color (product / custom)
    // or randolor
    return (
      <>
        <Input
            value={background.color}
            id={`background-color`}
            size="s"
            type="text"
            onChange={e => changeBackground({ ...background, product : e.target.value }) }
        />
        <Input
            value={background.url}
            id={`background-url`}
            size="s"
            type="text"
            onChange={e => changeBackground({ ...background, url : e.target.value }) }
        />
      </>
    )
}

function LogoInspector({ index, logo, currentProduct, changeLogo, removeLogo }) {
  // product?
  // url?
  return (
    <>
      <Input
          value={logo.product}
          id={`logo-product-${index}`}
          size="s"
          type="text"
          onChange={e => changeLogo(index, { ...logo, product : e.target.value }) }
      />
      <Input
          value={logo.url}
          id={`logo-url-${index}`}
          size="s"
          type="text"
          onChange={e => changeLogo(index, { ...logo, url : e.target.value }) }
      />
      <Button size="s" onClick={ e => removeLogo(index) }>Remove Logo ({ index })</Button>
    </>
  )
}

function ImageInspector({ index, image, changeImage, removeImage }) {
  // id?
  // url?
  return (
    <>
      <Input
          value={image.id}
          id={`image-id-${index}`}
          size="s"
          type="text"
          onChange={e => changeImage(index, { ...image, id : e.target.value }) }
      />
      <Input
          value={image.url}
          id={`image-url-${index}`}
          size="s"
          type="text"
          onChange={e => changeImage(index, { ...image, url : e.target.value }) }
      />
      <Button size="s" onClick={ e => removeImage(index) }>Remove Image ({ index })</Button>
    </>
  )
}

function ButtonInspector({ index, button, setButton, removeButton }) {
  return (
    <>
      <Input
          value={button.kind}
          id={`button-kind-${index}`}
          size="s"
          type="text"
          onChange={e => setButton(index, { ...button, kind : e.target.value }) }
      />
      <Input
          value={button.background.color || button.background.url}
          id={`button-background-${index}`}
          size="s"
          type="text"
          onChange={e => setButton(index, { ...button, background : e.target.value }) }
      />
      <Input
          value={button.text}
          id={`button-text-${index}`}
          size="s"
          type="text"
          onChange={e => setButton(index, { ...button, text : e.target.value }) }
      />
      <Button size="s" onClick={ e => removeButton(index) }>Remove Button ({ index })</Button>
    </>
  )
  // kind?
  // background
  // text
}

function TextInspector({ index, text, setText, removeText }) {
  return (
    <>
      <Input
          value={text.content}
          id={`text-content-${index}`}
          size="s"
          type="text"
          onChange={e => setText(index, { ...text, content : e.target.value }) }
      />
      <Input
          value={text.id}
          id={`text-id-${index}`}
          size="s"
          type="text"
          onChange={e => setText(index, { ...text, id : e.target.value }) }
      />
      <Input
          value={text.kind}
          id={`text-kind-${index}`}
          size="s"
          type="text"
          onChange={e => setText(index, { ...text, kind : e.target.value }) }
      />
      <Button size="s" onClick={ (e) => removeText(index) }>Remove Text ({ index })</Button>
    </>
  )
  // content
  // id : title, name, subtitle, product, version, date, subject, show-title, names, product-name, tagline, icon-prod-code, cursor
  // kind : h1, h2, text1, text2, h4
}

function GroupDrowpdown() {

}

function ItemInspector({ subject, onChange }) {
  /* here we edit the specific part of the template */
  return <></>;
}

const EditableTemplate = React.forwardRef(({ template, id, name, modifiers, fromCollection, zoom, allowEdits, changeSubject }, ref) => {
  const loadText = () => { return 'Sample text'; };
  const updateText = () => {};
  const loadImage = () => {};
  const updateImage = () => {};
  const logoSelect = (logo) => () => { changeSubject('logo', logo); };

  return (
      <Wrapper ref={ ref } width={ template.width } height={ template.height } template={ template } id={ id } modifiers={ modifiers } zoom={ zoom } >
        <Background bg={ template.background } width={ template.width } height={ template.height } />
        <Logos logos={ template.logos } onSelect={ logoSelect }/>
        <Buttons buttons={ template.buttons } />
        <Texts
          edits={ {} }
          shared={ {} }
          texts={ template.texts }
          loadText={ loadText }
          updateText={ updateText }
        />
        <Images images={ template.images } loadImage={ loadImage } updateImage={ updateImage } />
      </Wrapper>
  );
});

function SizesDropdown({ id, focus, collections, order, current }) {
  const options = collections.map(({ id, name }) => ({ value: id, label: name }));

  const currentOption = current ? { value : current.id, label : current.name } : options[0];
  //setSelected(currentOption);
  const setSelected = useCallback((selected) => {

  })

  return (
    <div className={ `select-wrapper` } id="collections">
      { focus ? <label htmlFor="collections">{ focus.name }</label> : <></> }
      <Select
        options={options}
        value={currentOption}
        onChange={value => setSelected(value)}
        size="m"
      />
    </div>
  )
}

function ProductsDropdown({ focus, products, current, onChange }) {
  const options = products.map(({ id, name }) => ({ value: id, label: name }));

  const currentOption = current ? { value : current.id, label : current.name } : options[0];
  //setSelected(currentOption);
  const setSelected = useCallback((item) => onChange({ id: item.value, name : item.label }));

  return (
    <div className={ `select-wrapper` } id="product">
      {/* focus ? <label htmlFor="product">{ focus.name }</label> : <></> */}
      <Select
        options={options}
        value={currentOption}
        onChange={value => setSelected(value)}
        size="s"
      />
    </div>
  )
}

export default Constructor;